// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-account-js": () => import("./../../../src/pages/account.js" /* webpackChunkName: "component---src-pages-account-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-callback-js": () => import("./../../../src/pages/callback.js" /* webpackChunkName: "component---src-pages-callback-js" */),
  "component---src-pages-categorias-js": () => import("./../../../src/pages/categorias.js" /* webpackChunkName: "component---src-pages-categorias-js" */),
  "component---src-pages-directory-js": () => import("./../../../src/pages/directory.js" /* webpackChunkName: "component---src-pages-directory-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-signup-js": () => import("./../../../src/pages/signup.js" /* webpackChunkName: "component---src-pages-signup-js" */),
  "component---src-pages-write-review-js": () => import("./../../../src/pages/write-review.js" /* webpackChunkName: "component---src-pages-write-review-js" */),
  "component---src-templates-category-group-js": () => import("./../../../src/templates/category-group.js" /* webpackChunkName: "component---src-templates-category-group-js" */),
  "component---src-templates-company-js": () => import("./../../../src/templates/company.js" /* webpackChunkName: "component---src-templates-company-js" */),
  "component---src-templates-info-js": () => import("./../../../src/templates/info.js" /* webpackChunkName: "component---src-templates-info-js" */)
}

