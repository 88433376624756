// Importing CSS files required by the Template. Although the instructions to introduce
// these CSS files is different that the one documented by the vendor, this approach
// is the one documented by Gatsby, the framework.  https://www.gatsbyjs.com/tutorial/part-two/
import "./src/styles/style.css"
import "./src/styles/vendors.css"

// or:
// require('./src/styles/global.css')
import React from "react"
import { silentAuth } from "./src/utils/auth"

class SessionCheck extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        loading: true,
      }
    }
  
    handleCheckSession = () => {
      this.setState({ loading: false })
    }
  
    componentDidMount() {
      silentAuth(this.handleCheckSession)
    }
  
    render() {
      return (
        this.state.loading === false && (
          <React.Fragment>{this.props.children}</React.Fragment>
        )
      )
    }
  }

  export const wrapRootElement = ({ element }) => {
    return <SessionCheck>{element}</SessionCheck>
  }